import {
  Exit,
  ExitRoom,
  ExitRoomAndTerminateMeeting,
  BaseAction,
  RefreshRoom,
  ResetRoomState,
  IgnoreAction,
  ForwardAction,
} from './actions/BaseActions.js';

import {
  Mute,
  Stfu,
  HandleStfu,
  PushToTalk,
  ToggleAudio,
  ToggleVideo,
  TrackUnmuted,
  StreamUpdate,
  DeviceUpdate,
  ToggleCamera,
  DeviceSupport,
  AcceptSession,
  ScreenAsVideo,
  ChangeScreenAsVideo,
  ScreenAsAdditionalUser,
  ScreenAsAdditionalUserActive,
  ScreenAsAdditionalUserStop,
  ScreenAsAdditionalUserEnded,
  ScreenCaptureError,
  HandleBrokenTrackError,
  RevitalizeRemoteStream,
  FixSafariSelfAudioBug,
  AudioDeviceWarning,
  VideoDeviceWarning,
  AllDevicesWarning,
  StartTabAudio,
  StopTabAudio,
  TabAudioActive,
  TabAudioEnded,
} from './actions/StreamActions.js';

import {
  HideDialog,
  ShowDropZone,
  ShowGuestLink,
  ShowCastDialog,
  ShowHelpDialog,
  ShowFilePreview,
  ShowShareDialog,
  ShowDataSaverDialog,
  ShowLayoutDialog,
  ShowFeedbackDialog,
  ShowRecordingDialog,
  ShowRecordingStopDialog,
  ShowRoomShareDialog,
  ShowLivestreamDialog,
  ShowExperimentalDialog,
  ShowLayoutSelectionDialog,
  ShowLockMeetingDialog,
  ShowExitDialog,
  ShowGuestExitDialog,
  ShowKickUserDialog,
  ShowRevitalizeStreamDialog,
  ShowYoutubePopup,
  SetYoutubeUserToken,
  ShowShareLocationDialog,
} from './actions/DialogActions.js';

import {
  StartMedia,
  StartPresenting,
  PresentationDrop,
  ScreenVideoEnded,
  PresentationEnded,
  HandleCaptureError,
  HandlePresentation,
  StartCanvasCapture,
  ChangeCameraOverlay,
  ActivateScreenCapture,
  StartScreenCapture,
  PresentationStarted,
  PresentationDropMedia,
  PresentationDropError,
  SetPresentingEvent,
  SetPresentionStreamEvent,
} from './actions/PresentationActions.js';

import {
  SetPip,
  TogglePip,
  ToggleSettings,
  ToggleMinified,
  CollapsedToolbar,
  ToggleFullScreen,
  ShowReactionPicker,
  ToggleChat,
  ToggleGiphy,
  ToggleTheme,
  HideReactionPicker,
  ToggleReactionPicker,
  ToggleMobileParticipants,
  ToggleMobileConnectionInfo,
} from './actions/VisibilityActions.js';

import {
  HandleOffline,
  HandleReconnect,
  HandleDisconnect,
} from './actions/ConnectionActions.js';

import {
  HandleError,
  HandleNotice,
  HandleWarning,
  LogAction,
} from './actions/ErrorHandlerActions.js';

import {
  StopLiveStream,
  UpdateBroadcasts,
  LiveStreamActivated,
  LiveStreamCancelled,
  HandleStartBroadcastError,
} from './actions/LiveStreamActions.js';

import {
  SetLayout,
  OptionsUpdate,
  ToggleNameInserts,
} from './actions/LayoutActions.js';

import {
  StopRecording,
  StartRecording,
  RecordingUpdate,
  RecordingError,
} from './actions/RecordingActions';

import { Snapshot, SnapshotUpdate } from './actions/SnapshotActions.js';

import {
  GifsUpdate,
  PlaybackFav,
  PlaybackStop,
  PlaybackStart,
  PlaybackEnded,
  PlaybackUpdate,
} from './actions/PlaybackActions.js';

import {
  StopAnnotation,
  StartAnnotation,
} from './actions/AnnotationActions.js';

import {
  AddUser,
  RemoveUser,
  KickUser,
  VoiceActivity,
  TalkingMutedAction,
  SuspendSpeechDetectionAction,
  ResumeSpeechDetectionAction,
} from './actions/UserInfoActions.js';

import { Logger } from 'eyeson';
import { Podium, RemoteDescriptionUpdate } from './actions/PodiumActions.js';
import {
  ReceiveChatMessage,
  ChatExportAction,
  HandleCustomMessage,
} from './actions/ChatActions.js';
import { HandleInactive, HandleActive } from './actions/InactivityActions.js';
import { HandleAudioNotificationEnded } from './actions/AudioActions.js';
import {
  LockMeeting,
  LockMeetingAction,
} from './actions/LockMeetingActions.js';
import { CopyGuestLink, SessionInfo } from './actions/KeyboardActions.js';
import {
  PipCamEvent,
  PipCamErrorEvent,
  PipCamVideoEndedWarning,
  PipCamUnsupportedWarning,
  TogglePipCamEvent,
} from './actions/PipCamActions.js';

/**
 * Default action is executed if no operation to process can be found. In
 * this case a message is posted to Logger, no further processing is done.
 **/
class DefaultAction extends BaseAction {
  process(state) {
    Logger.error(`Received event of type "${this.message.type}"
       which is not a registered event action. The message
       was ignored. Consider showing an error message to your user.`);
    return {};
  }
}

/**
 * Action Factory that holds action strategies. New actions can be added,
 * existing ones replaced on demand.
 **/
class ActionFactory {
  constructor() {
    this.actions = {
      accept: AcceptSession,
      track_unmuted: TrackUnmuted,
      local_track_unmuted: IgnoreAction,
      fetch_room: RefreshRoom,

      chat: ReceiveChatMessage,
      send_chat: ForwardAction,
      set_layer: ForwardAction,
      clear_front_layer: ForwardAction,

      add_user: AddUser,
      remove_user: RemoveUser,
      kick_user: KickUser,
      voice_activity: VoiceActivity,

      slide: IgnoreAction,
      next_slide: IgnoreAction,
      prev_slide: IgnoreAction,
      presentation_drop: PresentationDrop,
      presentation_drop_media: PresentationDropMedia,
      presentation_drop_error: PresentationDropError,
      presentation: HandlePresentation,
      stop_presentation: HandlePresentation,
      presentation_started: PresentationStarted,
      start_presenting: StartPresenting,
      change_camera_overlay: ChangeCameraOverlay,
      activate_screen_capture: ActivateScreenCapture,
      start_mixer: StartCanvasCapture,
      start_screen_capture: StartScreenCapture,
      presentation_ended: PresentationEnded,
      presentation_update: IgnoreAction,
      lock: LockMeetingAction,
      start_media: StartMedia,
      screen_video_ended: ScreenVideoEnded,
      set_presenting: SetPresentingEvent,
      set_presentation_stream: SetPresentionStreamEvent,

      // For client-logging
      marker_color: IgnoreAction,
      marker_click: IgnoreAction,
      clear_presentation_annotation: IgnoreAction,
      presentation_rotation: IgnoreAction,
      undo_click: IgnoreAction,
      redo_click: IgnoreAction,

      share: ShowShareDialog,
      feedback: ShowFeedbackDialog,
      recording: ShowRecordingDialog,
      recording_stop: ShowRecordingStopDialog,
      guest_link: ShowGuestLink,
      toggle_help: ShowHelpDialog,
      toggle_theme: ToggleTheme,
      live_stream: ShowLivestreamDialog,
      hide_dialog: HideDialog,
      show_drop_zone: ShowDropZone,
      copy_guest_link: IgnoreAction,
      guest_link_clipboard: CopyGuestLink,
      session_info: SessionInfo,
      chat_export: ChatExportAction,
      presentation_preview: ShowFilePreview,
      start_broadcast_error: HandleStartBroadcastError,
      audio_output_play_preview: IgnoreAction,
      layout_selection: ShowLayoutSelectionDialog,
      layout_dialog: ShowLayoutDialog,
      lock_meeting_prompt: ShowLockMeetingDialog,
      exit_prompt: ShowExitDialog,
      exit_prompt_guest: ShowGuestExitDialog,
      kick_user_prompt: ShowKickUserDialog,
      show_cast_dialog: ShowCastDialog,

      device_update: DeviceUpdate,
      device_support: DeviceSupport,
      toggle_video: ToggleVideo,
      toggle_audio: ToggleAudio,
      screen_as_video: ScreenAsVideo,
      screen_as_video_change: ChangeScreenAsVideo,
      screen_as_addition: ScreenAsAdditionalUser,
      screen_addition_active: ScreenAsAdditionalUserActive,
      screen_addition_stop: ScreenAsAdditionalUserStop,
      screen_addition_ended: ScreenAsAdditionalUserEnded,
      start_tab_audio: StartTabAudio,
      stop_tab_audio: StopTabAudio,
      tab_audio_active: TabAudioActive,
      tab_audio_ended: TabAudioEnded,
      screen_capture_error: ScreenCaptureError,
      stfu: HandleStfu,
      request_stfu: Stfu,
      mute: Mute,
      stream_update: StreamUpdate,
      audio_input_change: IgnoreAction,
      audio_output_change: IgnoreAction,
      video_input_change: IgnoreAction,
      virtual_background_change: IgnoreAction,
      push_to_talk: PushToTalk,
      suspend_virtual_background: ForwardAction,
      audio_device_ended: AudioDeviceWarning,
      video_device_ended: VideoDeviceWarning,
      all_devices_ended: AllDevicesWarning,

      settings: ToggleSettings,
      toggle_chat: ToggleChat,
      toggle_giphy: ToggleGiphy,
      toggle_minified: ToggleMinified,
      full_screen: ToggleFullScreen,
      set_pip: SetPip,
      toggle_pip: TogglePip,
      toggle_reaction_picker: ToggleReactionPicker,
      show_reaction_picker: ShowReactionPicker,
      hide_reaction_picker: HideReactionPicker,
      toggle_presentation_toolbar: IgnoreAction,
      lock_meeting: LockMeeting,
      toggle_mobile_participants: ToggleMobileParticipants,
      toggle_mobile_connection_info: ToggleMobileConnectionInfo,
      toggle_pip_cam: TogglePipCamEvent,

      snapshot: Snapshot,
      snapshot_update: SnapshotUpdate,

      stop_annotation: StopAnnotation,
      start_annotation: StartAnnotation,

      share_room_dialog: ShowRoomShareDialog,
      share_location_dialog: ShowShareLocationDialog,
      experimental: ShowExperimentalDialog,
      data_saver_dialog: ShowDataSaverDialog,
      revitalize_stream_dialog: ShowRevitalizeStreamDialog,

      toggle_camera: ToggleCamera,
      collapsed_toolbar: CollapsedToolbar,

      error: HandleError,
      warning: HandleWarning,
      clear_warning: IgnoreAction,
      notice: HandleNotice,
      capture_error: HandleCaptureError,
      log: LogAction,
      broken_track_error: HandleBrokenTrackError,
      revitalize_remote_stream: RevitalizeRemoteStream,
      fix_safari_audio: FixSafariSelfAudioBug,

      broadcasts_update: UpdateBroadcasts,
      stop_broadcasts: IgnoreAction,
      stop_live_stream: StopLiveStream,
      live_stream_activated: LiveStreamActivated,
      live_stream_cancelled: LiveStreamCancelled,
      set_layout: SetLayout,
      toggle_name_inserts: ToggleNameInserts,
      options_update: OptionsUpdate,

      start_recording: StartRecording,
      stop_recording: StopRecording,
      recording_update: RecordingUpdate,
      accept_recording: IgnoreAction,
      recording_error: RecordingError,

      gifs_update: GifsUpdate,
      playback_fav: PlaybackFav,
      playback_stop: PlaybackStop,
      playback_start: PlaybackStart,
      playback_update: PlaybackUpdate,
      playback_ended: PlaybackEnded,

      inactive: HandleInactive,
      active: HandleActive,

      exit: Exit,
      exit_room: ExitRoom,
      terminate: ExitRoomAndTerminateMeeting,

      room_ready: IgnoreAction,
      connection: IgnoreAction,
      disconnect: HandleDisconnect,
      reconnect: HandleReconnect,
      offline: HandleOffline,
      ignore: IgnoreAction,
      statistics_ready: IgnoreAction,
      custom: HandleCustomMessage,
      show_youtube_popup: ShowYoutubePopup,
      set_youtube_user_token: SetYoutubeUserToken,

      audio_notification_ended: HandleAudioNotificationEnded,

      podium: Podium,
      remote_description_update: RemoteDescriptionUpdate,
      talking_muted: TalkingMutedAction,
      suspend_speech_detection: SuspendSpeechDetectionAction,
      resume_speech_detection: ResumeSpeechDetectionAction,

      pip_cam: PipCamEvent,
      pip_cam_error: PipCamErrorEvent,
      pip_cam_warning_video_ended: PipCamVideoEndedWarning,
      pip_cam_warning_unsupported: PipCamUnsupportedWarning,

      reset_room_state: ResetRoomState,
    };
  }

  add(type, action) {
    if (type in this.actions) {
      Logger.error(`The action ${type} is already registered. Use .replace if
                   you really want to override a registered actions.`);
      return;
    }
    this.actions[type] = action;
  }

  replace(type, action) {
    if (!(type in this.actions)) {
      Logger.error(`The action ${type} is not registered. Use .add if you
                   want to add a new action.`);
      return;
    }
    this.actions[type] = action;
  }

  get(msg) {
    if (!msg.silenced) {
      Logger.debug('ActionFactory::getAction', msg.type);
    }
    return new (this.actions[msg.type] || DefaultAction)(msg);
  }
}

export default ActionFactory;

/* eslint-disable max-lines */
import config from './config.js';
import options from './options.js';
import ComApi from './ComApi.js';
import Logger from './Logger.js';
import throttle from './utils/throttle.js';
import EventHandler from './EventHandler.js';
import ConferenceSession from './ConferenceSession.js';
import ConnectionMonitor from './ConnectionMonitor.js';
import ActionCableConnection from './ActionCableConnection.js';
import { isObject } from './utils/utils.js';
import castingManager from './CastingManager.js';

/**
 * Initial connection status change updater.
 **/
const updateStatus = (eyeson, status) =>
  eyeson.core.eventHandler.send({
    type: 'connection',
    connectionStatus: status
  });

const keepRoomAlive = eyeson => {
  eyeson.core.keepRoomAlive = setInterval(() => {
    eyeson.core.rtConnection.send({ type: 'user_joins' });
  }, 30000);
};

/**
 * Remove this once WSS messages arrive reliably.
 **/
const pollingFallback = eyeson => {
  let counter = 1;
  eyeson.core.pollingFallbackInterval = setInterval(() => {
    const { core } = eyeson;
    if (counter === 200) {
      Logger.debug(
        'eyeson::pollingFallback: max count exceeded, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    if (core.eventHandler._connection) {
      Logger.debug(
        'eyeson::pollingFallback: connection set, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    core.comApi.getRoom(data => {
      if (data.ready === true) {
        Logger.debug('eyeson::pollingFallback: room ready');
        core.eventHandler.send({ type: 'room_ready', content: data });
        return;
      }
      Logger.debug('eyeson::pollingFallback: room not ready', counter);
      counter += 1;
    });
  }, 5000);
};

/**
 * Load initial room data.
 **/
const loadInitialInfos = eyeson => {
  const { core } = eyeson;
  const { broadcasts, presentation } = core.eventHandler._rtData;
  if (broadcasts) {
    core.eventHandler.send({
      type: 'broadcasts_update',
      broadcasts
    });
  }
  if (presentation) {
    core.eventHandler.send({
      type: 'presentation_update',
      presentation
    });
  }
};

/**
 * AudioSession API
 * helps to close the gap with platforms that have audio session/audio focus
 * such as Android and iOS. This API will help by improving the audio-mixing of
 * websites with native apps, so they can play on top of each other, or play
 * exclusively.
 * https://github.com/w3c/audio-session/blob/main/explainer.md
 */
const setBrowserAudioSession = () => {
  if (
    isObject(navigator.audioSession) &&
    Reflect.has(navigator.audioSession, 'type')
  ) {
    navigator.audioSession.type = 'play-and-record';
  }
};

/**
 * Join a session and listen to any events. eventHandler keeps all the
 * stuff.
 **/
// eslint-disable-next-line max-statements
const joinSession = (eyeson, mediaOptions) => {
  const { core } = eyeson;
  if (!core.eventHandler._connection) {
    Logger.error(
      'You tried to join a session that is not yet available. ' +
        'Before calling join, a connection status of connected has ' +
        'to be received.'
    );
    return;
  }

  const session = new ConferenceSession(
    core.eventHandler._connection,
    core.comApi,
    mediaOptions
  );
  session.setMonitor(core.eventHandler.monitor);
  session.setCastingControl(core.eventHandler.castingControl);
  core.eventHandler.session = session;

  session.start();
  loadInitialInfos(eyeson);
  clearInterval(core.keepRoomAlive);
  eyeson.session = session;
};

/**
 * Initialise our connections.
 **/
const prepareConnection = eyeson => {
  const { core } = eyeson;
  updateStatus(eyeson, 'fetch_room');

  core.eventHandler.eyeson = eyeson;

  core.comApi.onError((error, id) => {
    core.eventHandler.send({
      type: 'warning',
      name: 'error:comapi',
      id,
      status: error.status
    });
  });

  // eslint-disable-next-line max-statements
  core.comApi.getRoom(data => {
    if (data.error) {
      Logger.warn('eyeson::prepareConnection', data.error);
      updateStatus(eyeson, 'access_denied');
      return;
    }
    updateStatus(eyeson, 'received_room');

    core.rtConnection = new ActionCableConnection(data.links.websocket);
    core.eventHandler.rtConnection = core.rtConnection;
    core.rtConnection.startSession();

    core.eventHandler.monitor = new ConnectionMonitor();
    core.eventHandler.api = core.comApi;
    keepRoomAlive(eyeson);
    pollingFallback(eyeson);
  });
};

/****** The following represents the public API, adapt with caution! *********/
class Eyeson {
  /****** Public data ********************************************************/
  constructor() {
    /**
     * The room, user and links to be updated when fetched from the ComAPI.
     */
    this.config = config;
    this.core = { eventHandler: new EventHandler() };
    this.room = {};
    this.user = {};
    this.links = {};
    this.options = options;
    this.castingManager = castingManager.createInstance();
    this.core.eventHandler.castingControl = this.castingManager._castingControl;
    setBrowserAudioSession();
  }
  /****** Public helper methods **********************************************/

  /**
   * Attach event listener
   *
   * @param {function} listener - Event listener
   */
  onEvent(listener) {
    if (typeof listener !== 'function') {
      Logger.error(
        'A listener to eyeson events has to be of type function.' +
          ' The argument passed to onEvent is of type ' +
          typeof listener +
          '.'
      );
      return;
    }
    this.core.eventHandler.onReceive(listener);
  }

  /**
   * Remove event listener
   *
   * @param {function} [listener] - Event listener. Leave empty to remove all
   */
  offEvent(listener) {
    this.core.eventHandler.removeListener(listener);
  }

  /**
   * Prepare required core connections.
   *
   * @param {string} token - accessKey
   */
  connect(token) {
    Logger.debug('eyeson::connect', token);
    this.core.comApi = new ComApi(this.config.api, token);
    prepareConnection(this);
  }

  /**
   * Resolve permalink and prepare required core connections.
   *
   * @param {string} userToken - permalink user_token
   */
  connectPermalink(userToken) {
    Logger.debug('eyeson::connectPermalink', userToken);
    const api = new ComApi(this.config.api, userToken);
    updateStatus(this, 'fetch_room');
    api.joinPermalinkUser(data => {
      if (data.error) {
        Logger.warn('eyeson::connectPermalink', data.error);
        updateStatus(this, 'access_denied');
        return;
      }
      this.core.eventHandler.send({ type: 'permalink_ready', content: data });
      this.core.comApi = new ComApi(this.config.api, data.access_key);
      prepareConnection(this);
    });
  }

  /**
   * @typedef {object} MediaOptions
   * @prop {boolean} [audio] - default true
   * @prop {boolean} [video] - default true
   * @prop {boolean} [eco] - default false
   * @prop {boolean} [audioPassthrough] - default false
   */

  /**
   * Join a session with supplied mediaOptions (audio/video).
   *
   * @param {MediaOptions} mediaOptions - Media options like audio and video
   */
  join(mediaOptions) {
    Logger.debug('eyeson::join', mediaOptions);
    joinSession(this, mediaOptions);
  }

  /**
   * Start an eyeson room meeting.
   *
   * @param {string} token - accessKey
   * @param {MediaOptions} [mediaOptions] - Media options like audio and video
   */
  start(token, mediaOptions = { audio: true, video: true }) {
    Logger.debug('eyeson::start');
    const joinOnConnect = event => {
      if (event.connectionStatus !== 'ready') {
        return;
      }
      this.offEvent(joinOnConnect);
      this.join(mediaOptions);
    };
    this.onEvent(joinOnConnect);
    this.connect(token);
  }

  /**
   * Destroy and cleanup a session.
   */
  destroy() {
    const { core } = this;
    Logger.debug('eyeson::destroy');
    clearInterval(core.keepRoomAlive);
    clearInterval(core.pollingFallbackInterval);
    core.eventHandler.castingControl.terminate();
    this.castingManager.offUpdate();
    core.eventHandler.destroy();
    core.eventHandler = new EventHandler();
    this.castingManager = castingManager.createInstance();
    core.eventHandler.castingControl = this.castingManager._castingControl;
  }

  /**
   * Receive an event from client.
   *
   * @param {object} msg - Event object with type parameter
   */
  send(msg) {
    msg._src = 'client';
    this.core.eventHandler.send(msg);
  }

  /**
   * When invoked repeatedly, will only actually call the original function at
   * most once per every wait milliseconds.
   *
   * @param {object} msg - Event object with type parameter
   * @return {function} - A new, throttled send function
   */
  throttledSend(msg) {
    if (!this._throttledSend) {
      this._throttledSend = throttle(message => this.send(message), 500);
    }
    return this._throttledSend(msg);
  }

  /**
   * Create a new instance
   *
   * @return {Eyeson} - A new separate Eyeson session
   */
  createInstance() {
    return new Eyeson();
  }
}

const eyeson = new Eyeson();

export default eyeson;

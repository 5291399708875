/* eslint-disable max-lines */
import FeatureDetector from './FeatureDetector.js';
import { nullishCoalescing } from './utils/utils.js';

const supportsPresentationAPI = FeatureDetector.canCast();
const supportsCodecPreferences =
  typeof window.RTCRtpTransceiver === 'function' &&
  typeof window.RTCRtpTransceiver.prototype.setCodecPreferences === 'function';
const hasLocalStorage = FeatureDetector.hasLocalStorage();

class CastingManager {
  constructor() {
    /**
     * If casting supported. If false, a fallback as popup is provided.
     * Same as FeatureDetector.canCast()
     * @type {boolean}
     */
    this.supported = supportsPresentationAPI;
    /**
     * If presentation displays are available.
     * Check "availabilitychange" event
     * @type {boolean}
     */
    this.available = false;
    /**
     * If casting in this instance is already in use.
     * Check "blockingchange" event
     * @type {boolean}
     */
    this.blocked = false;
    /**
     * If casting is started or ended
     * Check "connected" and "terminated" events
     * @type {boolean}
     */
    this.connected = false;
    /**
     * If casting device is muted.
     * @type {boolean}
     */
    this.muted = true;
    /**
     * If casting device includes local audio when it is not muted.
     * @type {boolean}
     */
    this.includeLocalAudio = false;
    this._castingControl = null;
    this._listeners = [];
  }
  /**
   * @typedef {object} CastOptions
   * @prop {boolean} [muted] - default true
   * @prop {boolean} [includeLocalAudio] - default false
   */

  /**
   * Start casting service
   *
   * @param {CastOptions} [castOptions] - starting options { muted, includeLocalAudio }
   */
  start(options = {}) {
    const controlOptions = {
      volume: this.muted ? 0 : 1,
      localAudio: this.includeLocalAudio
    };
    if (typeof options.muted === 'boolean') {
      controlOptions.volume = options.muted ? 0 : 1;
    }
    if (typeof options.includeLocalAudio === 'boolean') {
      controlOptions.localAudio = options.includeLocalAudio;
    }
    this._castingControl.start(controlOptions);
  }
  /**
   * Stop casting session
   */
  stop() {
    this._castingControl.stop();
  }

  /**
   * Change muted state
   * @param {boolean} [muted] - default true
   */
  setMuted(muted = true) {
    this._castingControl.setVolume(muted ? 0 : 1);
  }
  /**
   * Change includeLocalAudio state
   * @param {boolean} [include] - default true
   */
  setIncludeLocalAudio(include = true) {
    this._castingControl.setLocalAudio(include);
  }

  /**
   * Register event listeners
   * ({ type, error }) => {}
   * @param {Function} listener
   */
  onUpdate(listener) {
    this._listeners.push(listener);
  }

  /**
   * Remove given event listener or all listeners
   * @param {Function} [listener]
   */
  offUpdate(listener = null) {
    if (!listener) {
      this._listeners.length = 0;
      return;
    }
    this._listeners = this._listeners.filter(fn => fn !== listener);
  }

  /**
   * PRIVATE! Emit events
   * @param {object} data
   */
  emit(data) {
    this._listeners.forEach(fn => fn(data));
  }
}

class CastingControl {
  // eslint-disable-next-line max-statements
  constructor(castingManager) {
    this.supported = supportsPresentationAPI;
    this.castingManager = castingManager;
    this.pc = null;
    this.streamLocal = null;
    this.streamRemote = null;
    this.audioTransceiverLocal = null;
    this.videoTransceiverLocal = null;
    this.audioTransceiverRemote = null;
    this.videoTransceiverRemote = null;
    this.request = null;
    this.connection = null;
    this.url = 'https://app.eyeson.team/casting-receiver.html';
    this.storageKey = 'eyeson.casting';
    this.blocked = false;
    this.available = false;
    this.connected = false;
    this.solo = false;
    this.sfu = false;
    this.hideLocal = false;
    this.hideRemote = false;
    this.volume = 0;
    this.localAudio = false;
    this.popup = null;
    this.popupTimer = null;
    this.popupMessageHandler = null;
    this.states = {
      solo: false,
      hasLayout: false,
      hasMutedVideoPeers: false
    };
    if (supportsPresentationAPI) {
      this.request = new PresentationRequest(this.url);
    }
    this._initLocalStorage();
    this._initAvailability();
  }

  _initAvailability() {
    // eslint-disable-next-line max-statements
    const onAvailableChange = available => {
      const oldBlocked = Boolean(this.blocked);
      const oldAvailable = this.available;
      if (available) {
        if (this.blocked) {
          this.blocked = 'available';
          this.available = false;
        } else {
          this.available = true;
        }
      } else {
        this.available = false;
      }
      if (this.available !== oldAvailable) {
        this.castingManager.available = this.available;
        this.castingManager.emit({ type: 'change_available' });
      }
      const newBlocked = Boolean(this.blocked);
      if (newBlocked !== oldBlocked) {
        this.castingManager.blocked = newBlocked;
        this.castingManager.emit({ type: 'change_blocked' });
      }
    };
    if (supportsPresentationAPI) {
      this.request.getAvailability().then(availability => {
        onAvailableChange(availability.value);
        availability.addEventListener('change', ({ target }) => {
          onAvailableChange(target.value);
        });
      });
    } else {
      onAvailableChange(true);
    }
  }

  _initLocalStorage() {
    if (hasLocalStorage) {
      // eslint-disable-next-line max-statements
      const onStorageChange = casting => {
        const oldBlocked = Boolean(this.blocked);
        const oldAvailable = this.available;
        if (casting) {
          if (this.available) {
            this.blocked = 'available';
            this.available = false;
          } else {
            this.blocked = true;
          }
        } else {
          if (this.blocked === 'available') {
            this.available = true;
          }
          this.blocked = false;
        }
        if (this.available !== oldAvailable) {
          this.castingManager.available = this.available;
          this.castingManager.emit({ type: 'change_available' });
        }
        const newBlocked = Boolean(this.blocked);
        if (newBlocked !== oldBlocked) {
          this.castingManager.blocked = newBlocked;
          this.castingManager.emit({ type: 'change_blocked' });
        }
      };
      const casting = window.localStorage.getItem(this.storageKey);
      onStorageChange(casting);
      window.addEventListener('storage', ({ key, newValue }) => {
        if (key === this.storageKey) {
          onStorageChange(newValue);
        }
      });
    }
  }

  _initPopup() {
    this.popup = window.open(
      `${this.url}?_=${Date.now()}`,
      'meeting-casting-receiver',
      'popup'
    );
    if (!this.popup) {
      this.castingManager.emit({ type: 'error', error: new Error('Unable to open popup') });
      return;
    }
    // eslint-disable-next-line max-statements
    this.popupMessageHandler = async event => {
      if (event.source !== this.popup) {
        return;
      }
      const data = JSON.parse(event.data);
      if (data.type === 'ready') {
        this.connected = true;
        this.castingManager.connected = true;
        this.castingManager.emit({ type: 'connected' });
        if (hasLocalStorage) {
          window.localStorage.setItem(this.storageKey, 'casting');
        }
        this._startPeerConnection();
        this.setVolume(this.volume);
        this.setSolo(this.solo);
        this.setSfu(this.sfu);
        this.setHideLocal(this.hideLocal);
        this.setHideRemote(this.hideRemote);
        this.setLocalAudio(this.localAudio);
      } else if (data.type === 'answer') {
        await this.pc.setRemoteDescription(data);
      }
    };
    window.addEventListener('message', this.popupMessageHandler);
    this.popupTimer = setInterval(() => {
      if (!this.popup || this.popup.closed) {
        this._cleanup();
        this.castingManager.emit({ type: 'terminated' });
      }
    }, 500);
  }

  async _initCasting() {
    try {
      navigator.presentation.defaultRequest = this.request;
      this.connection = await this.request.start();
      this.connection.addEventListener('message', async event => {
        const data = JSON.parse(event.data);
        if (data.type === 'ready') {
          this._startPeerConnection();
          this.setVolume(this.volume);
          this.setSolo(this.solo);
          this.setSfu(this.sfu);
          this.setHideLocal(this.hideLocal);
          this.setHideRemote(this.hideRemote);
          this.setLocalAudio(this.localAudio);
        } else if (data.type === 'answer') {
          await this.pc.setRemoteDescription(data);
        }
      });
      this.connection.addEventListener('connect', () => {
        this.connected = true;
        this.castingManager.connected = true;
        this.castingManager.emit({ type: 'connected' });
        if (hasLocalStorage) {
          window.localStorage.setItem(this.storageKey, 'casting');
        }
      });
      this.connection.addEventListener('close', () => {
        this.connection.terminate();
      });
      this.connection.addEventListener('terminate', () => {
        this._cleanup();
        this.castingManager.emit({ type: 'terminated' });
      });
    } catch (error) {
      this.castingManager.emit({ type: 'error', error });
    }
    navigator.presentation.defaultRequest = null;
  }

  start(options = {}) {
    this.volume = nullishCoalescing(options.volume, 0);
    this.localAudio = nullishCoalescing(options.localAudio, false);
    this.castingManager.muted = this.volume === 0;
    this.castingManager.includeLocalAudio = this.localAudio;
    if (supportsPresentationAPI) {
      this._initCasting();
    } else {
      this._initPopup();
    }
  }

  async _switchStream(stream, type) {
    this[`stream${type}`] = stream;
    if (this.pc) {
      const promises = [];
      stream.getTracks().forEach(track => {
        const transceiver = this[`${track.kind}Transceiver${type}`];
        if (transceiver && transceiver.sender) {
          promises.push(transceiver.sender.replaceTrack(track));
        }
      });
      await Promise.allSettled(promises);
    }
  }

  switchLocalStream(stream) {
    return this._switchStream(stream, 'Local');
  }

  switchRemoteStream(stream) {
    return this._switchStream(stream, 'Remote');
  }

  stop() {
    if (this.connection) {
      this.connection.terminate();
    } else if (this.popup) {
      this._cleanup();
      this.castingManager.emit({ type: 'terminated' });
    }
  }

  // eslint-disable-next-line max-statements
  async _startPeerConnection() {
    this.pc = new RTCPeerConnection();
    let codecs = null;
    if (supportsCodecPreferences) {
      const allCodecs = RTCRtpSender.getCapabilities('video').codecs;
      const av1 = allCodecs.filter(({ mimeType }) => mimeType === 'video/AV1');
      const vp9 = allCodecs.filter(({ mimeType }) => mimeType === 'video/VP9');
      // eslint-disable-next-line no-nested-ternary
      codecs = av1.length > 0 ? av1 : vp9.length > 0 ? vp9 : allCodecs;
    }
    this.streamLocal.getTracks().forEach(track => {
      const transceiver = this.pc.addTransceiver(track, {
        direction: 'sendonly',
        streams: [this.streamLocal]
      });
      if (track.kind === 'video' && supportsCodecPreferences) {
        transceiver.setCodecPreferences(codecs);
      }
      this[`${track.kind}TransceiverLocal`] = transceiver;
    });
    this.streamRemote.getTracks().forEach(track => {
      const transceiver = this.pc.addTransceiver(track, {
        direction: 'sendonly',
        streams: [this.streamRemote]
      });
      if (track.kind === 'video' && supportsCodecPreferences) {
        transceiver.setCodecPreferences(codecs);
      }
      this[`${track.kind}TransceiverRemote`] = transceiver;
    });
    this.pc.onicegatheringstatechange = () => {
      if (this.pc.iceGatheringState === 'complete') {
        this._sendToReceiver({
          type: 'offer',
          sdp: this.pc.localDescription.sdp
        });
      }
    };
    this._sendToReceiver({
      type: 'streamid',
      local: this.streamLocal.id,
      remote: this.streamRemote.id
    });
    const offer = await this.pc.createOffer();
    await this.pc.setLocalDescription(offer);
  }

  _sendToReceiver(data) {
    if (this.connection) {
      this.connection.send(JSON.stringify(data));
    } else if (this.popup) {
      this.popup.postMessage(JSON.stringify(data), 'https://app.eyeson.team');
    }
  }

  setVolume(volume) {
    this.volume = volume;
    this.castingManager.muted = volume === 0;
    this._sendToReceiver({ type: 'volume', volume });
  }

  setSolo(solo) {
    this.solo = solo;
    this._sendToReceiver({ type: 'solo', solo });
  }

  setSfu(sfu) {
    this.sfu = sfu;
    this._sendToReceiver({ type: 'sfu', sfu });
  }

  setHideLocal(hide) {
    this.hideLocal = hide;
    this._sendToReceiver({ type: 'hide-local', hide });
  }

  setHideRemote(hide) {
    this.hideRemote = hide;
    this._sendToReceiver({ type: 'hide-remote', hide });
  }

  setLocalAudio(enable) {
    this.localAudio = enable;
    this.castingManager.includeLocalAudio = enable;
    this._sendToReceiver({ type: 'local-audio', enable });
  }

  // eslint-disable-next-line max-statements
  _cleanup() {
    clearInterval(this.popupTimer);
    window.removeEventListener('message', this.popupMessageHandler);
    if (this.pc) {
      this.pc.close();
    }
    if (this.popup) {
      this.popup.close();
    }
    if (hasLocalStorage && this.connected) {
      window.localStorage.removeItem(this.storageKey);
    }
    this.popupMessageHandler = null;
    this.popupTimer = null;
    this.popup = null;
    this.pc = null;
    this.audioTransceiverLocal = null;
    this.videoTransceiverLocal = null;
    this.audioTransceiverRemote = null;
    this.videoTransceiverRemote = null;
    this.connection = null;
    this.connected = false;
    this.castingManager.connected = false;
  }

  terminate() {
    this._cleanup();
    this.streamLocal = null;
    this.streamRemote = null;
  }
}

export default {
  /**
   * Create a new CastingManager instance
   * @returns {CastingManager}
   */
  createInstance: () => {
    const castingManager = new CastingManager();
    const castingControl = new CastingControl(castingManager);
    castingManager._castingControl = castingControl;
    return castingManager;
  }
};

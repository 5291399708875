import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import ForwardEvent from './ForwardEvent.js';
import config from '../config.js';
import eyesonOptions from '../options.js';
import ChangeLocalStreamEvent from './ChangeLocalStreamEvent.js';
import {
  isCameraStream,
  isVBGStream,
  isScreenStream,
  hasAudio,
  hasVideo
} from './../utils/StreamHelpers.js';

const noChange = (lastPodiumSettings, msg) => {
  return Object.keys(lastPodiumSettings).every(
    key => lastPodiumSettings[key] === msg[key]
  );
};

const getVideoWidth = stream => {
  if (stream) {
    const [vTrack = null] = stream.getVideoTracks();
    if (vTrack && typeof vTrack.getSettings === 'function') {
      const { width = 0 } = vTrack.getSettings();
      return width;
    }
  }
  return 0;
};

class PodiumEvent extends BaseEvent {
  handle(msg) {
    // For dev
    Logger.debug('PodiumEvent::handle', msg);
    const { _session, castingControl } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session && !_session.externalStream) {
      this.scanMessageForLocalStreamOptimization(_session, msg);
      this.adjustResolution(_session, msg);
    }

    if (castingControl) {
      this.updateCasting(castingControl, msg);
    }

    new ForwardEvent(this.context).handle(msg);
  }

  // Optimization to avoid streaming when not on video podium.
  // First, we need a localStream, then we avoid adjustments when we initially
  // join the room (isSource) and finally if we're not on the podium,
  // but have a local camera stream with video, we change our local stream.
  //
  // We only re-activate the camera in the opposite case if the current
  // localStream was locallyChanged (through this optimization) since we
  // want to avoid situations where a user doesn't expect to be on the video
  // podium and we suddenly turned on their camera.
  //
  // Last case: someone else is presenting (screen share or canvas presentation)
  // we're still on the video podium but not actively presenting.
  // eslint-disable-next-line max-statements
  scanMessageForLocalStreamOptimization(session, msg) {
    const { localStream } = session;
    const { video, isSource, isPresenter, hasPresenter } = msg;

    if (!localStream) {
      return;
    }

    const streamSettings = {
      isCameraStream: isCameraStream(localStream),
      isVBGStream: isVBGStream(localStream),
      hasVideo: hasVideo(localStream),
      hasAudio: hasAudio(localStream),
      locallyChanged: localStream.locallyChanged
    };

    if (
      session.lastPodiumSettings &&
      noChange(
        session.lastPodiumSettings,
        Object.assign({}, msg, streamSettings)
      )
    ) {
      Logger.debug('scanMessageForLocalStreamOptimization: no change');
      return;
    }

    session.lastPodiumSettings = Object.assign(
      { video, isSource, isPresenter, hasPresenter },
      streamSettings
    );

    if (
      isSource &&
      ((!video &&
        (streamSettings.isCameraStream || streamSettings.isVBGStream)) ||
        (video &&
          !streamSettings.hasVideo &&
          streamSettings.locallyChanged &&
          !hasPresenter))
    ) {
      const options = {
        audio: streamSettings.hasAudio,
        video: video
      };
      new ChangeLocalStreamEvent(this.context).handle(options);
    }

    if (video && !isPresenter && hasPresenter) {
      new ChangeLocalStreamEvent(this.context).handle({
        audio: streamSettings.hasAudio,
        video: false
      });
    }
  }

  // eslint-disable-next-line max-statements
  async adjustResolution(session, msg) {
    if (!config.hdCamera) {
      return;
    }
    let factor = null;
    const { video, userDimensions, isPresenter } = msg;
    session.lastResolution = { video, userDimensions, isPresenter };
    if (isPresenter) {
      factor = 1.0;
    } else if (video && userDimensions) {
      const { localStream } = session;
      if (localStream) {
        if (isScreenStream(localStream)) {
          factor = 1.0;
        } else {
          const { w: width, h: height } = userDimensions;
          factor =
            width >= 640 ||
            height > (eyesonOptions.widescreen ? 360 : 480) ||
            getVideoWidth(localStream) <= 640
              ? 1.0
              : 0.5;
        }
      }
    }
    if (factor !== null) {
      try {
        let maxWidth = null;
        let maxHeight = null;
        Logger.info(
          'PodiumEvent::adjustResolution',
          factor === 0.5 ? 'half' : 'full'
        );
        if (factor === 0.5) {
          maxWidth = 640;
          maxHeight = eyesonOptions.widescreen ? 360 : 480;
        }
        await session.sipSession.sessionDescriptionHandler.scaleResolution(
          factor,
          maxWidth,
          maxHeight
        );
      } catch (error) {
        Logger.warn('PodiumEvent::adjustResolution', error);
      }
    }
  }

  updateCasting(castingControl, msg) {
    castingControl.states.solo = msg.solo;
    castingControl.states.hasMutedVideoPeers = msg.hasMutedVideoPeers;
    castingControl.setSolo(msg.solo && !castingControl.states.hasLayout);
    castingControl.setHideLocal(
      !msg.video || (msg.video && msg.hasPresenter && !msg.isPresenter)
    );
    castingControl.setHideRemote(castingControl.sfu && msg.hasMutedVideoPeers);
  }
}

export default PodiumEvent;

export const isObject = item => {
  return typeof item === 'object' && item !== null;
};

export const nullishCoalescing = (left, right) => {
  if (typeof left === 'undefined' || left === null) {
    return right;
  }
  return left;
};

import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { immediate } from 'eyeson';
import CastDialog from './dialog/CastDialog.js';
import HelpDialog from './dialog/HelpDialog.js';
import ShareDialog from './dialog/ShareDialog.js';
import ErrorDialog from './dialog/ErrorDialog.js';
import PromptDialog from './dialog/PromptDialog.js';
import LayoutDialog from './dialog/layout/LayoutDialog.js';
import DialogWindow from './dialog/DialogWindow.js';
import DataSaverDialog from './dialog/DataSaverDialog.js';
import FeedbackDialog from './dialog/FeedbackDialog.js';
import DeviceSettings from './device_settings/DeviceSettings.js';
import ShareLocationDialog from './dialog/ShareLocationDialog.js';
import QuickJoinDialog from './dialog/QuickJoinDialog.js';
import InactiveDialog from './dialog/InactiveDialog.js';
import RoomShareDialog from './dialog/RoomShareDialog.js';
import RemoteMuteDialog from './dialog/RemoteMuteDialog.js';
import LiveStreamDialog from './dialog/LiveStreamDialog.js';
import PresentationDialog from './dialog/PresentationDialog.js';
import LayoutSelectionDialog from './dialog/layout/LayoutSelectionDialog.js';
import ExperimentalBrowserAlert from './dialog/ExperimentalBrowserAlert.js';
import Utils from '../utils/a11yHelper.js';

const DialogWindowSelector = (props) => {
  const close = () => {
    props.context.onEvent({ type: 'hide_dialog' });
    const sourceElement = Utils.getRegisteredElement();
    if (sourceElement) {
      immediate(() => sourceElement.focus());
    }
  };
  let { active } = props;
  const {
    context,
    config: {
      times: { dialog: countDownDialogTime },
    },
    eyeson: {
      room,
      links: { guest_join },
      castingManager,
    },
  } = props;

  const dialog = {
    settings: () => {
      return (
        <DialogWindow
          id="device-dialog"
          title={I18n.t('dialog:title:settings')}
          maxWidth="md"
          fullWidth
          onClose={close}
        >
          <DeviceSettings
            onSave={close}
            onChange={context.onEvent}
            onClose={close}
            context={context}
          />
        </DialogWindow>
      );
    },
    guestlink: () => {
      return (
        <DialogWindow
          id="quick-join-dialog"
          title={I18n.t('dialog:title:quick_join')}
          onClose={close}
          useAriaDescription
        >
          <QuickJoinDialog
            dialogId="quick-join-dialog"
            token={room.guest_token}
            onCopy={context.onEvent}
            options={context.options}
          />
        </DialogWindow>
      );
    },
    presentation: () => {
      return (
        <DialogWindow
          id="share-dialog"
          title={I18n.t('dialog:title:presentation')}
          onClose={close}
          maxWidth="sm"
          useAriaDescription
        >
          <PresentationDialog
            dialogId="share-dialog"
            eco={context.eco}
            onEvent={context.onEvent}
            environment={context.environment}
            tabAudio={context.active.tabAudio}
            screenAsVideo={context.active.screenAsVideo}
            screenAddition={context.active.screenAddition}
            quickJoin={!!room.guest_token}
            isLocked={context.isLocked}
          />
        </DialogWindow>
      );
    },
    live_stream: () => {
      return (
        <DialogWindow
          id="live-stream-dialog"
          title={I18n.t('dialog:title:live_stream')}
          onClose={close}
        >
          <LiveStreamDialog
            eyeson={props.eyeson}
            health={context.liveStream.health}
            onEvent={context.onEvent}
            activated={context.liveStream.activated}
            widescreen={context.widescreen}
            broadcasts={context.broadcasts}
            liveStreamPhase={context.liveStream.phase}
            broadcastHelper={context.broadcastHelper}
          />
        </DialogWindow>
      );
    },
    share: () => {
      return (
        <DialogWindow
          id="share"
          title={I18n.t('dialog:title:share')}
          onClose={close}
        >
          {context.broadcasts.map((b) => (
            <ShareDialog url={b.player_url} />
          ))}
        </DialogWindow>
      );
    },
    room_share_dialog: () => {
      return (
        <DialogWindow
          id="share"
          title={I18n.t('dialog:title:room_share')}
          onClose={close}
        >
          <RoomShareDialog
            url={guest_join}
            options={context.options}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    recording: () => {
      return (
        <DialogWindow
          id="recording-dialog"
          title={I18n.t('dialog:title:recording')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="recording-dialog"
            message={I18n.t('dialog:recording:desc')}
            actionLabel={I18n.t('label:start')}
            onAction={() => context.onEvent({ type: 'start_recording' })}
          />
        </DialogWindow>
      );
    },
    recording_stop: () => {
      return (
        <DialogWindow
          id="recording-stop"
          role="alertdialog"
          title={I18n.t('dialog:title:recording_stop')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="recording-stop"
            message={I18n.t('dialog:recording_stop:desc')}
            actionLabel={I18n.t('label:stop')}
            onAction={() => context.onEvent({ type: 'stop_recording' })}
          />
        </DialogWindow>
      );
    },
    recording_started: () => {
      return (
        <DialogWindow
          id="recording"
          role="alertdialog"
          title={I18n.t('dialog:title:recording_started')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="recording"
            message={`${context.recording.user} ${I18n.t(
              'dialog:recording:started'
            )}`}
            actionLabel={I18n.t('dialog:prompt:agree')}
            cancelLabel={I18n.t('dialog:prompt:mute')}
            onAction={() => {
              context.onEvent({ type: 'accept_recording' });
              close();
            }}
            onClose={() => context.onEvent({ type: 'mute' })}
          />
        </DialogWindow>
      );
    },
    error_dialog: () => {
      return (
        <ErrorDialog
          title={context.error.title}
          href={context.error.url}
          description={context.error.message}
          primaryLabel={context.error.primaryLabel}
          onSecondaryClick={close}
        />
      );
    },
    experimental: () => {
      return (
        <DialogWindow
          id="experimental-dialog"
          title={I18n.t('dialog:title:experimental')}
          onClose={close}
        >
          <ExperimentalBrowserAlert />
        </DialogWindow>
      );
    },
    data_saver_dialog: () => {
      return (
        <DialogWindow
          id="data-saver-dialog"
          title={I18n.t('data_saver:title')}
          onClose={close}
        >
          <DataSaverDialog
            eco={context.eco}
            audio={context.active.audio}
            video={context.active.video}
            quality={context.quality}
            onEnter={context.onEnter}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_selection: () => {
      return (
        <DialogWindow
          id="layout-selection-dialog"
          title={I18n.t('dialog:title:layout')}
          onClose={close}
          maxWidth="sm"
          fullWidth
          useAriaDescription
        >
          <LayoutSelectionDialog
            dialogId="layout-selection-dialog"
            layoutName={context.options.layout_name}
            onEvent={context.onEvent}
            showNames={context.options.show_names}
            widescreen={context.widescreen}
          />
        </DialogWindow>
      );
    },
    layout_auto: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="auto"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_one: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="one"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_two: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="two"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_four: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="four"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_nine: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="nine"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_one_plus_six: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="one_plus_six"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_two_plus_six: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="two_plus_six"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    layout_widescreen_one_plus_six: () => {
      return (
        <DialogWindow
          id="layout-dialog"
          title={I18n.t('dialog:layout:title')}
          onClose={close}
        >
          <LayoutDialog
            type="widescreen_one_plus_six"
            users={context.participants}
            layoutType={context.options.layout}
            layoutName={context.options.layout_name}
            voiceActivation={context.options.voice_activation}
            onEvent={context.onEvent}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    help: () => (
      <DialogWindow
        id="help-dialog"
        title={I18n.t('dialog:title:help')}
        onClose={close}
      >
        <HelpDialog
          options={context.options}
          quickJoin={!!room.guest_token}
          hideChat={context.hideChat}
          hideGuestLogin={context.hideGuestLogin}
          hideScreenshare={context.hideScreenshare}
        />
      </DialogWindow>
    ),
    feedback: () => (
      <DialogWindow
        id="feedback-dialog"
        title={I18n.t('dialog:title:feedback')}
        onClose={close}
        useAriaDescription
      >
        <FeedbackDialog
          dialogId="feedback-dialog"
          url={context.feedbackUrl}
          onClose={close}
        />
      </DialogWindow>
    ),
    inactivity: () => {
      return (
        <DialogWindow
          id="inactive-dialog"
          title={I18n.t('dialog:title:inactive')}
          onClose={close}
          role="alertdialog"
          useAriaDescription
        >
          <InactiveDialog
            dialogId="inactive-dialog"
            context={context}
            countDownDialogTime={countDownDialogTime}
          />
        </DialogWindow>
      );
    },
    remote_mute: () => {
      return (
        <DialogWindow
          id="remote-mute"
          title={I18n.t('dialog:remote_mute:header')}
          onClose={close}
          role="alertdialog"
          useAriaDescription
        >
          <RemoteMuteDialog
            dialogId="remote-mute"
            user={context.remoteMute.user}
            onClick={close}
          />
        </DialogWindow>
      );
    },
    lock_meeting_prompt: () => {
      return (
        <DialogWindow
          id="lock-meeting-dialog"
          title={I18n.t('dialog:lock_meeting:header')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="lock-meeting-dialog"
            message={I18n.t('dialog:lock_meeting:desc')}
            actionLabel={I18n.t('label:lock_meeting:lock')}
            onAction={() => {
              context.onEvent({ type: 'lock_meeting' });
              close();
            }}
            cancelLabel={I18n.t('dialog:secondaryLabel')}
            onClose={close}
          >
            {I18n.t('dialog:lock_meeting:note')}
          </PromptDialog>
        </DialogWindow>
      );
    },
    exit_prompt: () => {
      return (
        <DialogWindow
          id="exit-room-dialog"
          title={I18n.t('dialog:end_meeting:header')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="exit-room-dialog"
            message={I18n.t('dialog:end_meeting:desc')}
            actionLabel={I18n.t('label:end_meeting:leave')}
            onAction={() => {
              context.onEvent({ type: 'exit_room' });
              close();
            }}
            secondaryActionLabel={I18n.t('label:end_meeting:end_for_all')}
            onSecondaryAction={() => {
              context.onEvent({ type: 'terminate' });
              close();
            }}
            cancelLabel={I18n.t('dialog:secondaryLabel')}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    exit_prompt_guest: () => {
      return (
        <DialogWindow
          id="exit-room-dialog"
          title={I18n.t('dialog:end_meeting_guest:header')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="exit-room-dialog"
            message={I18n.t('dialog:end_meeting_guest:desc')}
            actionLabel={I18n.t('label:end_meeting:leave')}
            onAction={() => {
              context.onEvent({ type: 'exit_room' });
              close();
            }}
            cancelLabel={I18n.t('dialog:secondaryLabel')}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    kick_user_prompt: () => {
      return (
        <DialogWindow
          id="kick-user-dialog"
          title={I18n.t('dialog:kick_user:header')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="kick-user-dialog"
            message={I18n.t('dialog:kick_user:desc', {
              user: context.kickUser.user.name,
            })}
            actionLabel={I18n.t('label:kick_user:remove')}
            onAction={() => {
              context.onEvent({
                type: 'kick_user',
                user: context.kickUser.user,
              });
              close();
            }}
            cancelLabel={I18n.t('dialog:secondaryLabel')}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    revitalize_stream_dialog: () => {
      return (
        <DialogWindow
          id="revitalize-stream-dialog"
          title={I18n.t('dialog:revitalize_stream:header')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="revitalize-stream-dialog"
            message={I18n.t('dialog:revitalize_stream:desc')}
            actionLabel={I18n.t('label:revitalize_stream:revitalize')}
            onAction={() => {
              close();
              immediate(() =>
                context.onEvent({ type: 'revitalize_remote_stream' })
              );
            }}
            cancelLabel={I18n.t('dialog:secondaryLabel')}
            onClose={close}
          />
        </DialogWindow>
      );
    },
    audio_device_lost_dialog: () => {
      return (
        <DialogWindow
          id="device-lost-dialog"
          title={I18n.t('dialog:device_lost:header:audio')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="device-lost-dialog"
            message={
              context.newAudioDevice
                ? I18n.t('dialog:device_lost:audio_name', {
                    name: context.newAudioDevice,
                  })
                : I18n.t('dialog:device_lost:audio')
            }
            actionLabel={I18n.t('dialog:primaryLabel')}
            onAction={() => {
              close();
            }}
          />
        </DialogWindow>
      );
    },
    video_device_lost_dialog: () => {
      return (
        <DialogWindow
          id="device-lost-dialog"
          title={I18n.t('dialog:device_lost:header:video')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="device-lost-dialog"
            message={I18n.t('dialog:device_lost:video')}
            actionLabel={I18n.t('dialog:primaryLabel')}
            onAction={() => {
              close();
            }}
          />
        </DialogWindow>
      );
    },
    all_devices_lost_dialog: () => {
      return (
        <DialogWindow
          id="device-lost-dialog"
          title={I18n.t('dialog:device_lost:header:all')}
          onClose={close}
          useAriaDescription
        >
          <PromptDialog
            dialogId="device-lost-dialog"
            message={
              context.newAudioDevice
                ? I18n.t('dialog:device_lost:all_name', {
                    name: context.newAudioDevice,
                  })
                : I18n.t('dialog:device_lost:all')
            }
            actionLabel={I18n.t('dialog:primaryLabel')}
            onAction={() => {
              close();
            }}
          />
        </DialogWindow>
      );
    },
    share_location_dialog: () => (
      <DialogWindow
        id="share-location-dialog"
        title={I18n.t('dialog:share_location:header')}
        onClose={close}
        useAriaDescription
        fullWidth={true}
        maxWidth="md"
      >
        <ShareLocationDialog
          dialogId="share-location-dialog"
          onEvent={context.onEvent}
          onClose={close}
        />
      </DialogWindow>
    ),
    cast_dialog: () => (
      <DialogWindow
        id="cast-dialog"
        title="Cast to device"
        onClose={close}
        useAriaDescription
        fullWidth={true}
        maxWidth="sm"
      >
        <CastDialog
          dialogId="cast-dialog"
          castingManager={castingManager}
          onClose={close}
        />
      </DialogWindow>
    ),
  }[active];
  return dialog ? dialog() : null;
};

DialogWindowSelector.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  config: PropTypes.object.isRequired,
  eyeson: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

DialogWindowSelector.defaultProps = {
  experimentalFeatures: false,
};

export default DialogWindowSelector;

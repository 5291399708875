import I18n from 'i18n-js';
import React, { useState } from 'react';
import Button from '../generic/Button.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { InputLabel, Switch } from '@material-ui/core';
import CastIcon from '@material-ui/icons/Cast';
import CastConnectedIcon from '@material-ui/icons/CastConnected';

const CastDialog = ({ castingManager, onClose }) => {
  const [castAudio, setCastAudio] = useState(!castingManager.muted);

  const startStopCasting = () => {
    if (castingManager.connected) {
      castingManager.stop();
      onClose();
      return;
    }
    setCastAudio(false);
    castingManager.start({ muted: true, includeLocalAudio: false });
  };

  const toggleCastAudio = () => {
    const newCastAudio = !castAudio;
    castingManager.setMuted(!newCastAudio);
    setCastAudio(newCastAudio);
  };

  return (
    <>
      <DialogContentText>
        Cast the meeting to a device, like a second monitor or large TV that
        supports Chromecast or a similar streaming feature.
      </DialogContentText>
      {castingManager.connected && (
        <InputLabel>
          <Switch
            checked={castAudio}
            onChange={toggleCastAudio}
            inputProps={{ 'aria-label': 'Play audio on device' }}
          />
          Play audio on device
        </InputLabel>
        // <InputLabel>
        //   <Switch
        //     disabled={castMuted}
        //     checked={castIncludeLocalAudio}
        //     onChange={() => {const newInclude = !castIncludeLocalAudio; castingManager.setIncludeLocalAudio(newInclude); setCastIncludeLocalAudio(newInclude)}}
        //     inputProps={{ 'aria-label': 'include local audio' }}
        //   />
        //   Include local audio
        // </InputLabel>
      )}
      <DialogActions>
        <Button type="secondary" onClick={onClose}>
          {I18n.t('label:cancel')}
        </Button>
        <Button
          onClick={startStopCasting}
          disabled={castingManager.blocked}
          icon={castingManager.connected ? <CastConnectedIcon /> : <CastIcon />}
        >
          {I18n.t('label:' + (castingManager.connected ? 'stop' : 'start'))}
        </Button>
      </DialogActions>
    </>
  );
};

export default CastDialog;

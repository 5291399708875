import BaseEvent from './BaseEvent.js';
import ForwardEvent from './ForwardEvent.js';

class OptionsUpdateEvent extends BaseEvent {
  handle(msg) {
    const { castingControl } = this.context;
    if (castingControl && castingControl.supported) {
      const hasLayout = msg.options.layout_users !== null;
      castingControl.states.hasLayout = hasLayout;
      castingControl.setSolo(castingControl.states.solo && !hasLayout);
    }
    new ForwardEvent(this.context).handle(msg);
  }
}

export default OptionsUpdateEvent;
